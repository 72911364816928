import * as React from "react";
import { navigate } from 'gatsby';
import { useAuth0 } from '../../hooks/use-auth0';

const CallbackHandler = () => {
    const { authProvider } = useAuth0();

    React.useEffect(() => {
        async function handleAuthCallback() {
            try {
                await authProvider.handleCallback();
                navigate('/admin');
            } catch (error) {
                console.error('Error during Auth0 callback:', error);
                navigate('/login');
            }
        }
        handleAuthCallback();
    }, [authProvider]);

    return (
        <>
            <h1>Logging In</h1>
        </>
    );
};

export default CallbackHandler;
